// @flow
import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "@mui/material";
import { getWithParentLinking } from "./util";

export default getWithParentLinking({
  path: "/",
  redirect: "/",
  title: "Dashboard",
  component: <Link component={NavLink} underline="hover" to="/">Dashboard</Link>,
  children: [
    { path: "", title: "", children: [] },
    {
      path: "/adNetwork/profiles/*",
      title: "AdNetwork Profiles Table",
    },
    {
      path: "/adNetwork/register/*",
      title: "Register a New AdNetwork",
    },
    {
      path: "/adNetwork/profile/:adNetworkId/view",
      title: "AdNetwork Profile",
    },
    {
      path: "/advertiser/domainValidation",
      title: "Advertiser Domain Validation",
    },
    {
      path: "/advertiser/profiles/*",
      title: "Advertiser Profiles Table",
    },
    {
      path: "/advertiser/register/*",
      title: "Register a New Advertiser",
    },
    {
      path: "/advertiser/profile/:hash/view",
      title: "Profile Advertiser",
    },
    {
      path: "/advertiser/profile/:hash/domains",
      title: "Advertiser domains",
    },
    {
      path: "/advertiser/profile/:hash/postbacks",
      title: "Postbacks",
    },
    {
      path: "/tools/managerRules/*",
      title: "Manager Distribution Rules",
    },
    {
      path: "/tools/modifications",
      title: "Modifications",
    },
    {
      path: "/tools/offerCampaigns",
      title: "Offer Setup Campaigns",
    },
    {
      path: "/tools/resApi",
      title: "Test ResAPI",
    },
    {
      path: "/tools/tdsApi",
      title: "Test TdsAPI",
    },
    {
      path: "/tools/autodeclineRules",
      title: "Custom Decline Rules",
    },
    {
      path: "/tools/subrateOptimisationTool",
      title: "Subrate Optimisation Tool",
    },
    {
      path: "/tools/subrateMultiEditor",
      title: "Subrate Multi-Editor",
    },
    {
      path: "/offers/searchIntOffers",
      title: "Find Internal Offers",
    },
    {
      path: "/offers/searchExtOffers",
      title: "Find External Offers",
    },
    {
      path: "/offers/bulkOfferLinks",
      title: "Bulk Offer Link Field Modification",
    },
    {
      path: "/offers",
      redirect: "/offers/searchExtOffers",
      title: "Find External Offers",
      component: <Link component={NavLink} underline="hover" to="/offers/searchExtOffers">Find External Offers</Link>,
      children: [
        {
          path: "/offer/ext/:offerId/info",
          title: "View Offer Info",
        },
        {
          path: "/offer/ext/createCpa",
          title: "Create an External CPA Offer",
        },
        {
          path: "/offer/ext/createCpa/:offerId",
          title: "Clone an External CPA Offer",
        },
        {
          path: "/offer/ext/createCpc",
          title: "Create an External CPC Offer",
        },
        {
          path: "/offer/ext/createCpc/:offerId",
          title: "Clone an External CPC Offer",
        },
      ],
    },
    {
      path: "/offers/search",
      title: "Find a Specific Offer",
    },
    {
      path: "/offers",
      redirect: "/offers/searchIntOffers",
      title: "Find Internal Offers",
      component: <Link component={NavLink} underline="hover" to="/offers/searchIntOffers">Find Internal Offers</Link>,
      children: [
        {
          path: "/offer/int/:offerId/info",
          title: "View Offer Info",
        },
      ],
    },
    {
      path: "/dashboard",
      title: "",
    },
    {
      path: "/smuProductCompany",
      redirect: "/smuProductCompany",
      title: "SMU Product Companies",
      children: [
        {
          path: "/smuProductCompany/create",
          title: "Create product company",
        },
        {
          path: "/smuProductCompany/:id",
          title: ({ params }) => `Edit product company: ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/smuDepartments",
      redirect: "/smuDepartments",
      title: "SMU Departments",
      children: [
        {
          path: "/smuDepartments/:id",
          title: ({ params }) => `Edit department: ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/smuBrands",
      redirect: "/smuBrands",
      title: "SMU Brands",
      children: [
        {
          path: "/smuBrands/create",
          title: "Create brands",
        },
        {
          path: "/smuBrands/:id",
          title: ({ params }) => `Edit brands: ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/historyChanges",
      title: "",
      children: [
        {
          path: "/historyChanges/affiliateProfile",
          title: "Affiliate Profile",
        },
        {
          path: "/historyChanges/affiliatePostback",
          title: "Affiliate Postback",
        },
        {
          path: "/historyChanges/affiliateSubrate",
          title: "Affiliate Subrate",
        },
        {
          path: "/historyChanges/affiliatePaymentInfo",
          title: "Affiliate Payment Info",
        },
        {
          path: "/historyChanges/offer",
          title: "Offer",
        },
        {
          path: "/historyChanges/extRate",
          title: "Ext Rates",
        },
        {
          path: "/historyChanges/*",
          title: "History Changes",
        },
      ],
    },
    {
      path: "/affiliates",
      title: "",
      children: [
        {
          path: "/affiliates/approve",
          title: "Affiliate Approve",
        },
        {
          path: "/affiliates/approve/pending",
          title: "Pending Affiliates",
        },
        {
          path: "/affiliates/approve/responded",
          title: "Responded Affiliates",
        },
        {
          path: "/affiliates/approve/autodeclined",
          title: "Auto-Declined Affiliates",
        },
        {
          path: "/affiliates/paymentUnavailableList",
          title: "Unavailable for Payment",
        },
        {
          path: "/affiliates/approve/allPending",
          title: "All Pending Affiliates",
        },
        {
          path: "/affiliates/approve/allResponded",
          title: "All Responded Affiliates",
        },
        {
          path: "/affiliates/profiles/*",
          title: "Affiliate Profiles Table",
        },
        {
          path: "/affiliates/profile/:affiliateId/view",
          title: "Affiliate Profile",
        },
        {
          path: "/affiliates/profile/:affiliateId/subrates",
          title: "Subrates",
        },
        {
          path: "/affiliates/profile/:affiliateId/extRates",
          title: "Ext Rates",
        },
        {
          path: "/affiliates/profile/:affiliateId/postbacks",
          title: "Postbacks",
        },
        {
          path: "/affiliates/profile/:affiliateId/payoutHistory",
          title: "Payout History",
        },
        {
          path: "/affiliates/profile/:affiliateId/crbooster",
          title: "CR Booster",
        },
        {
          path: "/affiliates/profile/:affiliateId/links",
          title: "Affiliate Links",
        },
        {
          path: "/affiliates/register",
          title: "Register a New Affiliate",
        },
        {
          path: "/affiliates/affAdminFeatures/applications/pending",
          title: "Pending Applications",
        },
        {
          path: "/affiliates/affAdminFeatures/applications/search",
          title: "Search for Applications",
        },
        {
          path: "/affiliates/affAdminFeatures/manageOfferwall",
          title: "Manage Offerwall Entries",
        },
        {
          path: "/affiliates/affAdminFeatures/extApplications/pending",
          title: "Pending External Applications",
        },
        {
          path: "/affiliates/affAdminFeatures/extApplications/searchAffiliates",
          title: "Search for External Applications",
        },
        {
          path: "/affiliates/affAdminFeatures/extApplications/search",
          title: "Search by External Affiliate Applications",
        },
        {
          path: "/affiliates/affAdminFeatures/extTLApplications/pending",
          title: "Pending External Applications (TL Access)",
        },
        {
          path: "/affiliates/affAdminFeatures/extTLApplications/searchAffiliates",
          title: "Search for External Applications (TL Access)",
        },
        {
          path: "/affiliates/affAdminFeatures/extTLApplications/search",
          title: "Search by External Affiliate Applications (TL Access)",
        },
        {
          path: "/affiliates/affAdminFeatures/topOffers",
          title: "Top Offers",
        },
        {
          path: "/affiliates/affAdminFeatures/smartOffers",
          title: "Manage Smart Offers",
        },
      ],
    },
    {
      path: "/manageAffiliate",
      redirect: "/manageAffiliate",
      title: "Affiliates",
      children: [
        {
          path: "/manageAffiliate/create",
          title: "Affiliate create",
        },
        {
          path: "/manageAffiliate/edit/:id",
          title: ({ params }) => `Affiliate edit ${String(params.id)}`,
        },
        {
          path: "/manageAffiliate/edit/:id/:tab",
          title: ({ params }) => `Affiliate edit ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/payments/paymentInfo",
      redirect: "/payments/paymentInfo",
      title: "Payment Info",
    },
    {
      path: "/payoutTerms",
      redirect: "/payoutTerms",
      title: "Payout terms",
      children: [
        {
          path: "/payoutTerms/create",
          title: "Payout terms create",
        },
        {
          path: "/payoutTerms/:id",
          title: "Payout terms edit",
        },
      ],
    },
    {
      path: "/cpaOffers",
      redirect: "/cpaOffers",
      title: "Cpa offers",
      children: [
        {
          path: "/cpaOffers/add",
          title: "Cpa offers create",
        },
        {
          path: "/cpaOffers/campaigns",
          title: "Cpa offer campaigns",
        },
        {
          path: "/cpaOffers/:id",
          title: ({ params }) => `Cpa offers edit ${String(params.id)}`,
        },
        {
          path: "/cpaOffers/:id/clone",
          title: ({ params }) => `Cpa offers clone ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/topOffers",
      redirect: "/topOffers",
      title: "Top offers",
      children: [
        {
          path: "/topOffers/create",
          title: "Top offers create",
        },
        {
          path: "/topOffers/:id",
          title: ({ params = {} }) => `Top offers edit ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/subrates",
      redirect: "/subrates",
      title: "Subrates",
      children: [
        {
          path: "/subrates/create",
          title: "Subrates create",
        },
        {
          path: "/subrates/edit/:id",
          title: ({ params = {} }) => `Subrates edit ${String(params.id)}`,
        },
        {
          path: "/subrates/info",
          title: "Subrates info",
        },
      ],
    },
    {
      path: "/potentialPartner",
      redirect: "/potentialPartner",
      title: "Potential partners",
      children: [
        {
          path: "/potentialPartner/create",
          title: "Potential partner create",
        },
        {
          path: "/potentialPartner/:id",
          title: ({ params = {} }) => `Potential partner edit ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/advertiserInvoices",
      redirect: "/advertiserInvoices",
      title: "Advertiser invoices",
      children: [
        {
          path: "/advertiserInvoices/create",
          title: "Advertiser invoices create",
        },
        {
          path: "/advertiserInvoices/:id",
          title: ({ params = {} }) => `Advertiser invoices edit ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/redefinedUtmCampaign",
      redirect: "/redefinedUtmCampaign",
      title: "Redefined utm_campaigns",
    },
    {
      path: "/crm/mails",
      redirect: "/crm/mails",
      title: "Mails",
      children: [
        {
          path: "/crm/mails/create",
          title: "Create mail",
        },
        {
          path: "/crm/mails/:id",
          title: ({ params }) => `Edit mail: ${String(params.id)}`,
        },
      ],
    },
    {
      path: "/crm/templates",
      redirect: "/crm/templates",
      title: "Templates",
      children: [
        {
          path: "/crm/templates/create",
          title: "Create template",
        },
        {
          path: "/crm/templates/:id",
          title: ({ params }) => `Edit template: ${String(params.id)}`,
        },
      ],
    },
  ],
});
